var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"md-layout"},[_c('md-dialog',{attrs:{"md-active":_vm.showDialogSource},on:{"update:mdActive":function($event){_vm.showDialogSource=$event},"update:md-active":function($event){_vm.showDialogSource=$event}}},[_c('md-dialog-content',[_c('ValidationObserver',{ref:"form"},[_c('form',{staticClass:"md-layout-item",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('md-dialog-title',[_vm._v("Cadastar DataSet")]),_c('md-dialog-actions',[_c('md-button',{staticClass:"md-danger",on:{"click":function($event){_vm.showDialogSource = false}}},[_vm._v("Fechar")]),_c('md-button',{staticClass:"md-success",attrs:{"type":"submit"}},[_vm._v("Salvar")])],1),_c('md-card',{staticClass:"md-layout-item md-size-100"},[_c('md-card-content',[_c('div',{staticClass:"md-layout md-gutter"},[_c('div',{staticClass:"md-layout md-layout-item md-size-50 md-xsmall-size-100"},[_c('div',{staticClass:"md-layout-item md-size-100"},[_c('ValidationProvider',{attrs:{"name":"fonte de dados","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('md-field',[_c('label',{attrs:{"for":"source_dataset_id"}},[_vm._v("Fonte de dados")]),_c('md-select',{attrs:{"name":"source_dataset_id"},model:{value:(_vm.dataSetType.source_dataset_id),callback:function ($$v) {_vm.$set(_vm.dataSetType, "source_dataset_id", $$v)},expression:"dataSetType.source_dataset_id"}},_vm._l((_vm.sources),function(source){return _c('md-option',{key:source.id,attrs:{"value":source.id}},[_vm._v(" "+_vm._s(source.name)+" ")])}),1),_c('validation-error',{attrs:{"errors":errors}})],1)]}}])})],1),_c('div',{staticClass:"md-layout-item md-size-100"},[_c('ValidationProvider',{attrs:{"name":"nome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('md-field',[_c('label',{attrs:{"for":"name"}},[_vm._v("Nome")]),_c('md-input',{attrs:{"name":"name","autocomplete":"name"},model:{value:(_vm.dataSetType.name),callback:function ($$v) {_vm.$set(_vm.dataSetType, "name", $$v)},expression:"dataSetType.name"}}),_c('validation-error',{attrs:{"errors":errors}})],1)]}}])})],1),_c('div',{staticClass:"md-layout-item md-size-100"},[_c('ValidationProvider',{attrs:{"name":"nome fantasia","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('md-field',[_c('label',{attrs:{"for":"name"}},[_vm._v("Nome Fantasia")]),_c('md-input',{attrs:{"name":"alias","autocomplete":"alias"},model:{value:(_vm.dataSetType.alias),callback:function ($$v) {_vm.$set(_vm.dataSetType, "alias", $$v)},expression:"dataSetType.alias"}}),_c('validation-error',{attrs:{"errors":errors}})],1)]}}])})],1),_c('div',{staticClass:"md-layout-item md-size-100"},[_c('ValidationProvider',{attrs:{"name":"tipo de dataset","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('md-field',[_c('label',{attrs:{"for":"type"}},[_vm._v("Tipo de dataset")]),_c('md-select',{attrs:{"name":"type"},model:{value:(_vm.dataSetType.type),callback:function ($$v) {_vm.$set(_vm.dataSetType, "type", $$v)},expression:"dataSetType.type"}},[_c('md-option',{attrs:{"value":"1"}},[_vm._v("Arquivo DBF")]),_c('md-option',{attrs:{"value":"2"}},[_vm._v("Arquivo CSV")]),_c('md-option',{attrs:{"value":"3"}},[_vm._v("Arquivo HTML")]),_c('md-option',{attrs:{"value":"4"}},[_vm._v("API")]),_c('md-option',{attrs:{"value":"5"}},[_vm._v("API/CSV")])],1),_c('validation-error',{attrs:{"errors":errors}})],1)]}}])})],1)]),_c('div',{staticClass:"md-layout md-layout-item md-size-50 md-xsmall-size-100"},[_c('div',{staticClass:"md-layout-item md-size-100"},[_c('md-field',[_c('label',{attrs:{"for":"prefix"}},[_vm._v("Prefixo")]),_c('md-input',{attrs:{"name":"prefix","autocomplete":"prefix"},model:{value:(_vm.dataSetType.prefix),callback:function ($$v) {_vm.$set(_vm.dataSetType, "prefix", $$v)},expression:"dataSetType.prefix"}})],1)],1),_c('div',{staticClass:"md-layout-item md-size-100"},[_c('ValidationProvider',{attrs:{"name":"chaves","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('md-field',[_c('label',{attrs:{"for":"name"}},[_vm._v("Colunas Chaves")]),_c('md-input',{attrs:{"name":"keys","autocomplete":"keys","placeholder":"Colunas chaves: col1, col2"},model:{value:(_vm.dataSetType.keys),callback:function ($$v) {_vm.$set(_vm.dataSetType, "keys", $$v)},expression:"dataSetType.keys"}}),_c('validation-error',{attrs:{"errors":errors}})],1)]}}])})],1),_c('div',{staticClass:"md-layout-item md-size-100"},[_c('ValidationProvider',{attrs:{"name":"coluna data","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('md-field',[_c('label',{attrs:{"for":"name"}},[_vm._v("Coluna ano do dataset")]),_c('md-input',{attrs:{"name":"col_date_dataset","autocomplete":"col_date_dataset","placeholder":"Coluna ano do dataset"},model:{value:(_vm.dataSetType.col_date_dataset),callback:function ($$v) {_vm.$set(_vm.dataSetType, "col_date_dataset", $$v)},expression:"dataSetType.col_date_dataset"}}),_c('validation-error',{attrs:{"errors":errors}})],1)]}}])})],1),_c('div',{staticClass:"md-layout-item md-size-100"},[_c('ValidationProvider',{attrs:{"name":"formato data data","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('md-field',[_c('label',{attrs:{"for":"format_date"}},[_vm._v("Formato da coluna ano")]),_c('md-select',{attrs:{"name":"col_date_dataset_format"},model:{value:(_vm.dataSetType.col_date_dataset_format),callback:function ($$v) {_vm.$set(_vm.dataSetType, "col_date_dataset_format", $$v)},expression:"dataSetType.col_date_dataset_format"}},[_c('md-option',{attrs:{"value":"Y"}},[_vm._v("AAAA")]),_c('md-option',{attrs:{"value":"d/m/Y"}},[_vm._v("DD/MM/AAAA")]),_c('md-option',{attrs:{"value":"Y-m-d"}},[_vm._v("AAAA-MM-DD")]),_c('md-option',{attrs:{"value":"Y-m-d H:i:s"}},[_vm._v(" AAAA-MM-DD hh:mm:ss ")]),_c('md-option',{attrs:{"value":"d-m-Y H:i:sBR"}},[_vm._v("D-MMM-AAAA H:i:s BR")]),_c('md-option',{attrs:{"value":"d-M-Y H:i:s"}},[_vm._v("D-MMM-AAAA H:i:s")]),_c('md-option',{attrs:{"value":"Ymd"}},[_vm._v("AAAAMMDD")]),_c('md-option',{attrs:{"value":"dmY"}},[_vm._v("DDMMAAAA")])],1),_c('validation-error',{attrs:{"errors":errors}})],1)]}}])})],1)]),_c('div',{staticClass:"md-layout-item md-size-100"},[_c('ValidationProvider',{attrs:{"name":"formato data data","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('md-field',[_c('label',{attrs:{"for":"format_date"}},[_vm._v("Formato geral das datas do dataset")]),_c('md-select',{attrs:{"name":"format_date"},model:{value:(_vm.dataSetType.format_date),callback:function ($$v) {_vm.$set(_vm.dataSetType, "format_date", $$v)},expression:"dataSetType.format_date"}},[_c('md-option',{attrs:{"value":"Y"}},[_vm._v("AAAA")]),_c('md-option',{attrs:{"value":"d/m/Y"}},[_vm._v("DD/MM/AAAA")]),_c('md-option',{attrs:{"value":"Y-m-d"}},[_vm._v("AAAA-MM-DD")]),_c('md-option',{attrs:{"value":"Y-m-d H:i:s"}},[_vm._v(" AAAA-MM-DD hh:mm:ss ")]),_c('md-option',{attrs:{"value":"d-m-Y H:i:sBR"}},[_vm._v("D-MMM-AAAA H:i:s BR")]),_c('md-option',{attrs:{"value":"d-M-Y H:i:s"}},[_vm._v("D-MMM-AAAA H:i:s")]),_c('md-option',{attrs:{"value":"Ymd"}},[_vm._v("AAAAMMDD")]),_c('md-option',{attrs:{"value":"dmY"}},[_vm._v("DDMMAAAA")])],1),_c('validation-error',{attrs:{"errors":errors}})],1)]}}])})],1)]),_c('div',{staticClass:"md-layout-item md-size-100"},[_c('md-card',[_c('md-card-content',[_c('md-table',{staticClass:"paginated-table table-striped table-hover",attrs:{"value":_vm.table,"md-sort":"name","md-sort-order":"asc","md-card":""},on:{"md-selected":_vm.onSelect},scopedSlots:_vm._u([{key:"md-table-row",fn:function(ref){
var item = ref.item;
return _c('md-table-row',{class:_vm.getClass(item),attrs:{"md-selectable":"single"}},[_c('md-table-cell',{attrs:{"md-label":"Name","md-sort-by":"name"}},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('md-table-cell',{attrs:{"md-label":"Source"}},[_vm._v(" "+_vm._s(item.source.name)+" ")]),_c('md-table-cell',{attrs:{"md-label":"Actions"}},[_c('md-button',{staticClass:"md-accent",on:{"click":function($event){return _vm.deleteItem(item.id)}}},[_c('md-icon',[_vm._v("delete")])],1)],1)],1)}}])},[_c('md-table-toolbar',[_c('md-field',[_c('label',[_vm._v("Per page")]),_c('md-select',{attrs:{"name":"pages"},model:{value:(_vm.pagination.perPage),callback:function ($$v) {_vm.$set(_vm.pagination, "perPage", $$v)},expression:"pagination.perPage"}},_vm._l((_vm.pagination.perPageOptions),function(item){return _c('md-option',{key:item,attrs:{"label":item,"value":item}},[_vm._v(" "+_vm._s(item)+" ")])}),1)],1)],1)],1)],1),_c('md-card-actions',{attrs:{"md-alignment":"space-between"}},[_c('div',{},[_c('p',{staticClass:"card-category"},[_vm._v(" Showing "+_vm._s(_vm.from + 1)+" to "+_vm._s(_vm.to)+" of "+_vm._s(_vm.total)+" entries ")])]),_c('pagination',{staticClass:"pagination-no-border",attrs:{"per-page":_vm.pagination.perPage,"total":_vm.total},on:{"input":_vm.setPage},model:{value:(_vm.pagination.currentPage),callback:function ($$v) {_vm.$set(_vm.pagination, "currentPage", $$v)},expression:"pagination.currentPage"}})],1)],1)],1)])],1)],1)])],1)],1),_c('md-button',{staticClass:"md-info md-raised",on:{"click":function($event){return _vm.showDialog()}}},[_vm._v(" Cadastar DataSet ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }