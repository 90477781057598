<template>
  <div class="select">
    <md-field v-on-click-outside="deactivate">
      <md-chip
        v-for="chip in value"
        :key="chip.id"
        :md-deletable="true"
        :md-clickable="true"
        @keydown.enter="$emit('md-click', chip, key)"
        @click.native="$emit('md-click', chip, key)"
        @md-delete.stop="onRemoveItem(chip)"
      >
        <slot name="md-chip" :chip="chip">
          <template v-if="sortingProperty && indexProperty">
            <div class="md-ripple">
              {{ chip[indexProperty] }}: {{ chip[sortingProperty] }}
            </div>
          </template>
          <template v-else>
            <div class="md-ripple">{{ chip }}</div>
          </template>
        </slot>
      </md-chip>

      <md-input
        v-model="searchedText"
        @focus.prevent="activate()"
        @keyup.esc="deactivate()"
        placeholder="Filtros por CID"
        ref="search"
      >
      </md-input>
    </md-field>
    <div v-if="showList" class="allitems">
      <ul>
        <li
          class="list"
          v-for="item in allItems"
          :key="item.id"
          @click="onSelectItem(item)"
        >
          <template v-if="sortingProperty && indexProperty">
            <span>{{ item[indexProperty] }}: {{ item[sortingProperty] }}</span>
          </template>
          <template v-else>
            {{ item }}
          </template>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mixin as onClickOutside } from "vue-on-click-outside";

const sortBy = key => {
  return (a, b) => (a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0);
};

export default {
  mounted() {
    this.selectedItems = [];
  },
  props: {
    options: {
      type: Array,
      required: true
    },
    value: {
      type: Array
    },
    sortingProperty: {
      type: String,
      default: null
    },
    indexProperty: {
      type: String,
      default: null
    },
    height: {
      type: String,
      default: "60"
    }
  },
  mixins: [onClickOutside],
  data() {
    return {
      searchedText: null,
      selectedItems: null,
      showList: false
    };
  },
  computed: {
    allItems() {
      let items = [...this.options];
      if (!this.sortingProperty) return items.sort();
      return items.sort(sortBy(this.sortingProperty));
    },
    filteredAllItems() {
      if (this.searchedText) {
        let filteredItems = this.allItems.filter(i => {
          if (this.sortingProperty) {
            return i[this.sortingProperty]
              .toLowerCase()
              .includes(this.searchedText.toLowerCase());
          } else {
            return i.toLowerCase().includes(this.searchedText.toLowerCase());
          }
        });
        return filteredItems;
      } else {
        return this.allItems;
      }
    }
  },
  watch: {
    searchedText: function(val) {
      if (val != null) {
        this.$emit("change", val);
      }
    }
  },
  methods: {
    onSelectItem(item) {
      let checkItem = this.selectedItems.find(
        selectedItem => selectedItem.id == item.id
      );
      if (checkItem == undefined) {
        this.selectedItems.push(item);
      } else {
        this.$store.dispatch("alerts/error", "Item já esta na lista");
        return false;
      }

      this.$emit("input", this.selectedItems);

      let indexOfItem = this.allItems.findIndex(i => {
        return i.id === item.id;
      });
      this.allItems.splice(indexOfItem, 1);
      this.searchedText = null;
    },
    onRemoveItem(item) {
      this.allItems.push(item);
      this.sortingProperty
        ? this.allItems.sort(sortBy(this.sortingProperty))
        : this.allItems.sort();

      let indexOfItem = this.selectedItems.findIndex(i => {
        return i.id === item.id;
      });
      this.selectedItems.splice(indexOfItem, 1);
      this.$emit("input", this.selectedItems);
    },
    activate() {
      this.showList = true;
    },
    deactivate() {
      this.showList = false;
    }
  }
};
</script>

<style lang="scss">
.select {
  min-width: 100%;
  background-color: #ffffff;
  text-align: start;
  position: relative;
}

.md-chips.md-field {
  padding-top: 12px;
  flex-wrap: wrap;

  &.md-has-value {
    label {
      top: -6px;
    }
  }

  .md-chip {
    margin-bottom: 4px;

    &:last-of-type {
      margin-right: 8px;
    }
  }

  .md-input {
    min-width: 128px;
  }
}

.allitems {
  position: absolute;
  background-color: rgba(220, 220, 220, 0.7);
  cursor: pointer;
  border: 1px solid #dbdbdb;
  max-height: 200px;
  height: calc(100vh - 240px);
  overflow-y: scroll;
  width: 100.1%;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .list {
    color: #363636;
    text-align: start;
    padding: 6px;
    transition: 0.3s;

    &:hover {
      background: rgba(50, 115, 220, 1);
      color: #363636;
    }
  }
}
</style>
