<template>
  <md-card>
    <md-card-header data-background-color="primary">
      <h4 class="title">Filtro por CIDs</h4>
    </md-card-header>
    <md-card-content>
      <md-list :md-expand-single="true">
        <md-list-item md-expand :md-expanded="true">
          <md-icon>search</md-icon>
          <span class="md-list-item-text">Filtro por Capítulo CID 10</span>

          <md-list slot="md-expand">
            <md-list-item>
              <div class="md-layout">
                <md-button
                  class="md-raised md-dense md-info md-m-1"
                  @click="updateChaptersCids"
                >
                  <md-icon>filter_alt</md-icon>
                  Filtrar
                </md-button>
                <md-button
                  v-if="loggedIn && selectedChaptersCids.length > 0"
                  class="md-raised md-dense md-info md-m-1"
                  @click="
                    () => {
                      selectedChaptersCids = [];
                    }
                  "
                >
                  <md-icon>clear</md-icon>
                  Limpar Filtros
                </md-button>
              </div>
            </md-list-item>
            <md-list-item>
              <div class="md-layout">
                <div class="md-layout-item md-size-100">
                  <chips-autocomplete
                    v-model="selectedChaptersCids"
                    :options="chaptersCids"
                    :sorting-property="'name'"
                    indexProperty="starting_code"
                    @change="getChaptersCids"
                    height="350"
                  ></chips-autocomplete>
                </div>
              </div>
            </md-list-item>
          </md-list>
        </md-list-item>
        <md-list-item md-expand>
          <md-icon>search</md-icon>
          <span class="md-list-item-text">Filtro por Grupo CID 10</span>

          <md-list slot="md-expand">
            <md-list-item>
              <div class="md-layout">
                <md-button
                  class="md-raised md-dense md-info md-m-1"
                  @click="updateGroupsCids"
                >
                  <md-icon>filter_alt</md-icon>
                  Filtrar
                </md-button>
                <md-button
                  v-if="loggedIn && selectedGroupsCids.length > 0"
                  class="md-raised md-dense md-info md-m-1"
                  @click="
                    () => {
                      selectedGroupsCids = [];
                    }
                  "
                >
                  <md-icon>clear</md-icon>
                  Limpar Filtros
                </md-button>
              </div>
            </md-list-item>
            <md-list-item>
              <div class="md-layout">
                <div class="md-layout-item md-size-100">
                  <chips-autocomplete
                    v-model="selectedGroupsCids"
                    :options="groupsCids"
                    :sorting-property="'name'"
                    indexProperty="starting_code"
                    @change="getGroupsCids"
                    height="350"
                  ></chips-autocomplete>
                </div>
              </div>
            </md-list-item>
          </md-list>
        </md-list-item>
        <md-list-item md-expand>
          <md-icon>search</md-icon>
          <span class="md-list-item-text">Filtro por Categoria/Sub CID 10</span>

          <md-list slot="md-expand">
            <md-list-item>
              <div class="md-layout">
                <md-button
                  class="md-raised md-dense md-info md-m-1"
                  @click="updateCids"
                >
                  <md-icon>filter_alt</md-icon>
                  Filtrar
                </md-button>
                <md-button
                  v-if="loggedIn && selectedCids.length > 0"
                  class="md-raised md-dense md-info md-m-1"
                  @click="
                    () => {
                      selectedCids = [];
                      filterItem = null;
                    }
                  "
                >
                  <md-icon>clear</md-icon>
                  Limpar Filtros
                </md-button>
              </div>
            </md-list-item>
            <md-list-item style="padding: 5px;">
              <div class="md-layout">
                <div class="md-layout-item md-size-60 md-small-size-100">
                  <chips-autocomplete
                    v-model="selectedCids"
                    :options="cids"
                    :sorting-property="'description'"
                    indexProperty="code_dot"
                    @change="getCids"
                    height="350"
                  ></chips-autocomplete>
                </div>
                <div class="md-layout-item md-size-40 md-small-size-100">
                  <dialog-save-filter
                    v-if="loggedIn && selectedCids.length > 0"
                    :selectedCids="selectedCids"
                    @addQueryFilter="getQueryFilters"
                  ></dialog-save-filter>
                  <div class="custom-filter">
                    <md-chip
                      class="md-rose"
                      v-for="queryFilter in queryFilters"
                      :key="queryFilter.id"
                      :md-deletable="
                        loggedIn && queryFilter.user_id == user.id
                          ? true
                          : false
                      "
                      md-clickable
                      @click="setSelectedCids(queryFilter)"
                      @md-delete="deleteQueryFilter(queryFilter)"
                    >
                      #{{ queryFilter.name }}
                    </md-chip>
                  </div>
                </div>
              </div>
            </md-list-item>
          </md-list>
        </md-list-item>
      </md-list>
    </md-card-content>
  </md-card>
</template>

<script>
import Vue from "vue";
import ChipsAutocomplete from "../ChipsAutocomplete";
import DialogSaveFilter from "../Form/DialogSaveFilter.vue";

export default {
  components: {
    ChipsAutocomplete,
    DialogSaveFilter
  },
  data() {
    return {
      selectedCids: [],
      selectedChaptersCids: [],
      selectedGroupsCids: [],
      queryFilters: [],
      cids: [],
      chaptersCids: [],
      groupsCids: [],
      filterItem: null
    };
  },
  computed: {
    loggedIn: function() {
      return this.$store.state.auth.status.loggedIn;
    },
    user: function() {
      return this.$store.state.auth.user;
    }
  },
  async mounted() {
    this.getQueryFilters();
  },
  methods: {
    async getQueryFilters() {
      try {
        let result = await Vue.$http.get(`v1/query_filter/`);
        this.queryFilters = result.data.data;
      } catch (e) {
        console.error(e);
        //await this.$store.dispatch("alerts/error", e);
      }
    },
    async getGroupsCids(keyword) {
      try {
        if (keyword.length < 3) {
          this.groupsCids = [];
          return true;
        }

        let result = await Vue.$http.get(`v1/cid/group/?keyword=${keyword}`);
        this.groupsCids = result.data;
      } catch (e) {
        await this.$store.dispatch("alerts/error", e);
      }
    },
    async getChaptersCids(keyword) {
      try {
        if (keyword.length < 3) {
          this.chaptersCids = [];
          return true;
        }

        let result = await Vue.$http.get(`v1/cid/chapter/?keyword=${keyword}`);
        this.chaptersCids = result.data;
      } catch (e) {
        await this.$store.dispatch("alerts/error", e);
      }
    },
    async getCids(keyword) {
      try {
        if (keyword.length < 3) {
          this.cids = [];
          return true;
        }

        let result = await Vue.$http.get(`v1/cid/?keyword=${keyword}`);
        this.cids = result.data;
      } catch (e) {
        await this.$store.dispatch("alerts/error", e);
      }
    },
    setSelectedCids(queryFilter) {
      this.selectedCids = [];
      if (queryFilter == null) {
        return;
      }
      for (const filter_cid of queryFilter.filter_cids) {
        this.selectedCids.push(filter_cid.cid);
      }
    },
    deleteQueryFilter(queryFilter) {
      console.error(queryFilter);
    },
    updateGroupsCids() {
      this.$emit("update", {
        type: "groupsCids",
        data: this.selectedGroupsCids
      });
    },
    updateChaptersCids() {
      this.$emit("update", {
        type: "chaptersCids",
        data: this.selectedChaptersCids
      });
    },
    updateCids() {
      this.$emit("update", { type: "cids", data: this.selectedCids });
    }
  }
};
</script>

<style lang="scss" scoped>
.custom-filter {
  display: flex;
  flex-wrap: wrap;
}
</style>
