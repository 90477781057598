<template>
  <div class="md-layout">
    <md-dialog :md-active.sync="showDialogSource">
      <md-dialog-content>
        <ValidationObserver ref="form">
          <form class="md-layout-item" @submit.prevent="onSubmit">
            <md-dialog-title>Cadastar DataSet</md-dialog-title>
            <md-dialog-actions>
              <md-button class="md-danger" @click="showDialogSource = false"
                >Fechar</md-button
              >
              <md-button class="md-success" type="submit">Salvar</md-button>
            </md-dialog-actions>
            <md-card class="md-layout-item md-size-100">
              <md-card-content>
                <div class="md-layout md-gutter">
                  <div
                    class="md-layout md-layout-item md-size-50 md-xsmall-size-100"
                  >
                    <div class="md-layout-item md-size-100">
                      <ValidationProvider
                        name="fonte de dados"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <md-field>
                          <label for="source_dataset_id">Fonte de dados</label>
                          <md-select
                            v-model="dataSetType.source_dataset_id"
                            name="source_dataset_id"
                          >
                            <md-option
                              v-for="source in sources"
                              :key="source.id"
                              :value="source.id"
                            >
                              {{ source.name }}
                            </md-option>
                          </md-select>
                          <validation-error :errors="errors" />
                        </md-field>
                      </ValidationProvider>
                    </div>
                    <div class="md-layout-item md-size-100">
                      <ValidationProvider
                        name="nome"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <md-field>
                          <label for="name">Nome</label>
                          <md-input
                            name="name"
                            autocomplete="name"
                            v-model="dataSetType.name"
                          />
                          <validation-error :errors="errors" />
                        </md-field>
                      </ValidationProvider>
                    </div>
                    <div class="md-layout-item md-size-100">
                      <ValidationProvider
                        name="nome fantasia"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <md-field>
                          <label for="name">Nome Fantasia</label>
                          <md-input
                            name="alias"
                            autocomplete="alias"
                            v-model="dataSetType.alias"
                          />
                          <validation-error :errors="errors" />
                        </md-field>
                      </ValidationProvider>
                    </div>

                    <div class="md-layout-item md-size-100">
                      <ValidationProvider
                        name="tipo de dataset"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <md-field>
                          <label for="type">Tipo de dataset</label>
                          <md-select v-model="dataSetType.type" name="type">
                            <md-option value="1">Arquivo DBF</md-option>
                            <md-option value="2">Arquivo CSV</md-option>
                            <md-option value="3">Arquivo HTML</md-option>
                            <md-option value="4">API</md-option>
                            <md-option value="5">API/CSV</md-option>
                          </md-select>
                          <validation-error :errors="errors" />
                        </md-field>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div
                    class="md-layout md-layout-item md-size-50 md-xsmall-size-100"
                  >
                    <div class="md-layout-item md-size-100">
                      <md-field>
                        <label for="prefix">Prefixo</label>
                        <md-input
                          name="prefix"
                          autocomplete="prefix"
                          v-model="dataSetType.prefix"
                        />
                      </md-field>
                    </div>
                    <div class="md-layout-item md-size-100">
                      <ValidationProvider
                        name="chaves"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <md-field>
                          <label for="name">Colunas Chaves</label>
                          <md-input
                            name="keys"
                            autocomplete="keys"
                            v-model="dataSetType.keys"
                            placeholder="Colunas chaves: col1, col2"
                          />
                          <validation-error :errors="errors" />
                        </md-field>
                      </ValidationProvider>
                    </div>
                    <div class="md-layout-item md-size-100">
                      <ValidationProvider
                        name="coluna data"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <md-field>
                          <label for="name">Coluna ano do dataset</label>
                          <md-input
                            name="col_date_dataset"
                            autocomplete="col_date_dataset"
                            v-model="dataSetType.col_date_dataset"
                            placeholder="Coluna ano do dataset"
                          />
                          <validation-error :errors="errors" />
                        </md-field>
                      </ValidationProvider>
                    </div>

                    <div class="md-layout-item md-size-100">
                      <ValidationProvider
                        name="formato data data"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <md-field>
                          <label for="format_date">Formato da coluna ano</label>
                          <md-select
                            v-model="dataSetType.col_date_dataset_format"
                            name="col_date_dataset_format"
                          >
                            <md-option value="Y">AAAA</md-option>
                            <md-option value="d/m/Y">DD/MM/AAAA</md-option>
                            <md-option value="Y-m-d">AAAA-MM-DD</md-option>
                            <md-option value="Y-m-d H:i:s">
                              AAAA-MM-DD hh:mm:ss
                            </md-option>
                            <md-option value="d-m-Y H:i:sBR"
                              >D-MMM-AAAA H:i:s BR</md-option
                            >
                            <md-option value="d-M-Y H:i:s"
                              >D-MMM-AAAA H:i:s</md-option
                            >
                            <md-option value="Ymd">AAAAMMDD</md-option>
                            <md-option value="dmY">DDMMAAAA</md-option>
                          </md-select>
                          <validation-error :errors="errors" />
                        </md-field>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="md-layout-item md-size-100">
                    <ValidationProvider
                      name="formato data data"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <md-field>
                        <label for="format_date"
                          >Formato geral das datas do dataset</label
                        >
                        <md-select
                          v-model="dataSetType.format_date"
                          name="format_date"
                        >
                          <md-option value="Y">AAAA</md-option>
                          <md-option value="d/m/Y">DD/MM/AAAA</md-option>
                          <md-option value="Y-m-d">AAAA-MM-DD</md-option>
                          <md-option value="Y-m-d H:i:s">
                            AAAA-MM-DD hh:mm:ss
                          </md-option>
                          <md-option value="d-m-Y H:i:sBR"
                            >D-MMM-AAAA H:i:s BR</md-option
                          >
                          <md-option value="d-M-Y H:i:s"
                            >D-MMM-AAAA H:i:s</md-option
                          >
                          <md-option value="Ymd">AAAAMMDD</md-option>
                          <md-option value="dmY">DDMMAAAA</md-option>
                        </md-select>
                        <validation-error :errors="errors" />
                      </md-field>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="md-layout-item md-size-100">
                  <md-card>
                    <md-card-content>
                      <md-table
                        :value="table"
                        md-sort="name"
                        md-sort-order="asc"
                        md-card
                        class="paginated-table table-striped table-hover"
                        @md-selected="onSelect"
                      >
                        <md-table-toolbar>
                          <md-field>
                            <label>Per page</label>
                            <md-select
                              v-model="pagination.perPage"
                              name="pages"
                            >
                              <md-option
                                v-for="item in pagination.perPageOptions"
                                :key="item"
                                :label="item"
                                :value="item"
                              >
                                {{ item }}
                              </md-option>
                            </md-select>
                          </md-field>
                        </md-table-toolbar>

                        <md-table-row
                          slot="md-table-row"
                          slot-scope="{ item }"
                          :class="getClass(item)"
                          md-selectable="single"
                        >
                          <md-table-cell md-label="Name" md-sort-by="name">
                            {{ item.name }}
                          </md-table-cell>
                          <md-table-cell md-label="Source">
                            {{ item.source.name }}
                          </md-table-cell>
                          <md-table-cell md-label="Actions">
                            <md-button
                              class="md-accent"
                              @click="deleteItem(item.id)"
                            >
                              <md-icon>delete</md-icon>
                            </md-button>
                          </md-table-cell>
                        </md-table-row>
                      </md-table>
                    </md-card-content>

                    <md-card-actions md-alignment="space-between">
                      <div class="">
                        <p class="card-category">
                          Showing {{ from + 1 }} to {{ to }} of
                          {{ total }} entries
                        </p>
                      </div>
                      <pagination
                        class="pagination-no-border"
                        v-model="pagination.currentPage"
                        :per-page="pagination.perPage"
                        :total="total"
                        @input="setPage"
                      />
                    </md-card-actions>
                  </md-card>
                </div>
              </md-card-content>
            </md-card>
          </form>
        </ValidationObserver>
      </md-dialog-content>
    </md-dialog>

    <md-button class="md-info md-raised" @click="showDialog()">
      Cadastar DataSet
    </md-button>
  </div>
</template>

<script>
import { ValidationError, Pagination } from "@/components";
import formMixin from "@/mixins/form-mixin";
import Vue from "vue";

export default {
  components: {
    ValidationError,
    Pagination
  },
  mixins: [formMixin],
  data() {
    return {
      showDialogSource: false,
      logo: {},
      dataSetType: {
        id: null,
        name: null,
        alias: null,
        keys: null,
        col_date_dataset: null,
        col_date_dataset_format: null,
        format_date: null,
        prefix: null,
        type: null,
        source_dataset_id: null
      },
      url: "v1/type-dataset/",
      table: [],
      total: 0,
      footerTable: ["Nome", "Ações"],

      query: null,

      sortation: {
        field: "name",
        order: "asc"
      },

      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [10, 15, 20, 25, 50]
      },

      apiUrl: `${process.env.VUE_APP_API_BASE_URL}/`,

      sources: []
    };
  },
  created() {},
  computed: {
    sort() {
      if (this.sortation.order === "desc") {
        return `-${this.sortation.field}`;
      }

      return this.sortation.field;
    },

    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    }
  },
  methods: {
    setPage(page) {
      this.pagination.currentPage = page;
      this.getList();
    },
    async getList() {
      try {
        let result = await Vue.$http.get(
          `${this.url}?per_page=${this.pagination.perPage}&page=${this.pagination.currentPage}`
        );
        this.table = result.data.data;
        this.total = result.data.total;
      } catch (e) {
        await this.$store.dispatch("alerts/error", e.response.data.message);
        //this.setApiValidation(e.response.data.errors);
      }
    },
    async getSouceList() {
      try {
        let result = await Vue.$http.get(`v1/source-dataset/?all=true`);
        this.sources = result.data;
      } catch (e) {
        await this.$store.dispatch("alerts/error", e.response.data.message);
        //this.setApiValidation(e.response.data.errors);
      }
    },
    async alert(message) {
      await this.$store.dispatch("alerts/error", message);
    },
    async create() {
      try {
        await Vue.$http.post(`${this.url}`, this.dataSetType);

        this.dataSetType = {
          id: null,
          name: null,
          alias: null,
          keys: null,
          col_date_dataset: null,
          col_date_dataset_format: null,
          format_date: null,
          prefix: null,
          type: null,
          source_dataset_id: null
        };
        this.getList();
        this.show = false;
        await this.$store.dispatch("alerts/success", "Recurso Cadastrado!");
        this.$emit("addSource", { addSource: true });
      } catch (error) {
        const message =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
        console.log(message);
        for (let e in error.response.data.errors) {
          await this.$store.dispatch(
            "alerts/error",
            error.response.data.errors[e]
          );
        }
        this.show = false;
      }
    },
    async update() {
      try {
        await Vue.$http.put(
          `${this.url}${this.dataSetType.id}`,
          this.dataSetType
        );

        this.dataSetType = {
          id: null,
          name: null,
          alias: null,
          keys: null,
          col_date_dataset: null,
          col_date_dataset_format: null,
          format_date: null,
          prefix: null,
          type: null,
          source_dataset_id: null
        };
        this.getList();
        this.show = false;
        await this.$store.dispatch("alerts/success", "Recurso Atualizado!");
        this.$emit("addSource", { addSource: true });
      } catch (error) {
        const message =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
        console.log(message);
        for (let e in error.response.data.errors) {
          await this.$store.dispatch(
            "alerts/error",
            error.response.data.errors[e]
          );
        }

        this.show = false;
      }
    },
    async onSubmit() {
      this.$refs.form.validate().then(async success => {
        if (!success) {
          let values = Object.values(this.$refs.form.errors);
          values.forEach(value => {
            value.forEach(message => {
              this.alert(message);
            });
          });
          return;
        }
        if (this.dataSetType.id == null) {
          await this.create();
        } else {
          await this.update();
        }
      });
    },
    async deleteItem(id) {
      await Vue.$http.delete(`${this.url}${id}`);
      this.getList();
    },
    showDialog() {
      this.showDialogSource = true;
      this.getList();
      this.getSouceList();
    },
    getClass: () => {
      return "md-primary";
    },
    async onSelect(item) {
      console.log(item);
      this.dataSetType = {
        id: item.id,
        name: item.name,
        alias: item.alias,
        keys: item.keys,
        col_date_dataset: item.col_date_dataset,
        col_date_dataset_format: item.col_date_dataset_format,
        format_date: item.format_date,
        prefix: item.prefix,
        type: item.type,
        source_dataset_id: item.source_dataset_id
      };
    },
    clear() {
      this.dataSetType = {
        id: null,
        name: null,
        alias: null,
        keys: null,
        col_date_dataset: null,
        col_date_dataset_format: null,
        format_date: null,
        prefix: null,
        type: null,
        source_dataset_id: null
      };
    }
  },
  closeDialogType() {
    this.dataSetType = {
      id: null,
      name: null,
      alias: null,
      keys: null,
      col_date_dataset: null,
      col_date_dataset_format: null,
      format_date: null,
      prefix: null,
      type: null,
      source_dataset_id: null
    };
    this.showDialogSource = false;
  }
};
</script>

<style lang="scss" scoped>
.md-dialog .md-dialog-container {
  width: 60% !important;
}
</style>
