<template>
  <div class="md-layout md-layout-item md-alignment-center-center">
    <md-dialog :md-active.sync="showDialogCropper">
      <md-dialog-content>
        <form class="md-layout-item" @submit.prevent="onSubmit">
          <md-dialog-title>Inserir Logo {{ item.name }}</md-dialog-title>
          <md-dialog-actions>
            <md-button class="md-primary" @click="clear">Limpar</md-button>
            <md-button class="md-danger" @click="closeDialogCropper"
              >Fechar</md-button
            >
            <md-button class="md-success" type="submit">Salvar</md-button>
          </md-dialog-actions>

          <div class="md-layout md-gutter md-alignment-center-center">
            <div
              class="md-layout md-layout-item md-size-65 md-medium-size-85 md-small-size-95 md-xsmall-size-100 md-alignment-center-center"
            >
              <croppa
                :width="400"
                :height="400"
                :placeholder="'Selecione a logo'"
                v-model="logo"
              ></croppa>
            </div>
          </div>
        </form>
      </md-dialog-content>
    </md-dialog>

    <md-button class="md-primary md-raised" @click="showDialog()">
      Inserir Logo
    </md-button>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  name: "DialogCropper",
  components: {},
  props: {
    item: {
      type: Object,
      required: true
    },
    url: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      showDialogCropper: false,
      logo: {},
      apiUrl: `${process.env.VUE_APP_API_BASE_URL}/`
    };
  },
  created() {},
  computed: {},
  methods: {
    async alert(message) {
      await this.$store.dispatch("alerts/error", message);
    },
    async update() {
      this.logo.generateBlob(
        async blob => {
          try {
            let formData = new FormData();
            formData.append("_method", "PUT");
            formData.append("logo", blob, `logo.png`);
            await Vue.$http.post(`${this.url}${this.item.id}`, formData, {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": `multipart/form-data; boundary=${formData._boundary}`
              },
              timeout: 9999999
            });
            this.show = false;
            this.$emit("addImage", { addImage: true });
            this.showDialogCropper = false;
          } catch (error) {
            const message =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
            this.alert(message);
            this.show = false;
          }
        },
        "image/png",
        0.8
      );
    },
    async onSubmit() {
      await this.update();
    },
    showDialog() {
      this.showDialogCropper = true;
    },
    closeDialogCropper() {
      this.showDialogCropper = false;
      this.logo.remove();
    },
    clear() {
      this.logo.remove();
    }
  }
};
</script>

<style lang="scss" scoped>
.md-dialog .md-dialog-container {
  width: 400px !important;
}
</style>
