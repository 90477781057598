<template>
  <div class="md-layou-item">
    <md-dialog :md-active.sync="showDialogSource">
      <md-dialog-content>
        <ValidationObserver ref="form">
          <form class="md-layout-item" @submit.prevent="onSubmit">
            <md-dialog-title>Salvar Filtro</md-dialog-title>
            <md-dialog-actions>
              <md-button class="md-danger" @click="showDialogSource = false"
                >Fechar</md-button
              >
              <md-button class="md-success" type="submit">Salvar</md-button>
            </md-dialog-actions>
            <md-card class="md-layout-item md-size-100">
              <md-card-content>
                <div class="md-layout md-gutter">
                  <div class="md-layout md-layout-item md-size-100">
                    <div class="md-layout-item md-size-100">
                      <ValidationProvider
                        name="nome"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <md-field>
                          <label for="name">Nome</label>
                          <md-input
                            name="name"
                            autocomplete="name"
                            v-model="queryFilter.name"
                          />
                          <validation-error :errors="errors" />
                        </md-field>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="md-layout md-layout-item md-size-100">
                    <div class="md-layout-item md-size-100">
                      <md-switch v-model="queryFilter.is_public"
                        >Filtro público</md-switch
                      >
                    </div>
                  </div>
                </div>
              </md-card-content>
            </md-card>
          </form>
        </ValidationObserver>
      </md-dialog-content>
    </md-dialog>

    <md-button
      class="md-success md-dense md-raised md-m-1"
      @click="showDialog()"
    >
      <md-icon>save</md-icon>
      Salvar Filtro
    </md-button>
  </div>
</template>

<script>
import { ValidationError } from "@/components";
import formMixin from "@/mixins/form-mixin";
import Vue from "vue";

export default {
  components: {
    ValidationError
  },
  mixins: [formMixin],
  props: {
    selectedCids: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      showDialogSource: false,
      url: "v1/query_filter/",
      logo: {},
      queryFilter: {
        id: null,
        name: null,
        is_public: null
      },
      apiUrl: `${process.env.VUE_APP_API_BASE_URL}/`
    };
  },
  created() {},
  computed: {},
  methods: {
    async alert(message) {
      await this.$store.dispatch("alerts/error", message);
    },
    async create() {
      try {
        await Vue.$http.post(`${this.url}`, this.queryFilter);

        this.queryFilter = {
          id: null,
          name: null,
          is_public: null
        };
        this.show = false;
        await this.$store.dispatch("alerts/success", "Recurso Cadastrado!");
        this.clear();
        this.showDialogSource = false;
        this.$emit("addQueryFilter", { addQueryFilter: true });
      } catch (error) {
        const message =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
        console.log(message);
        for (let e in error.response.data.errors) {
          await this.$store.dispatch(
            "alerts/error",
            error.response.data.errors[e]
          );
        }
        this.show = false;
      }
    },
    async onSubmit() {
      this.$refs.form.validate().then(async success => {
        if (!success) {
          let values = Object.values(this.$refs.form.errors);
          values.forEach(value => {
            value.forEach(message => {
              this.alert(message);
            });
          });
          return;
        }
        this.queryFilter.cids = [];
        for (const cid of this.selectedCids) {
          this.queryFilter.cids.push(cid.id);
        }
        await this.create();
      });
    },
    showDialog() {
      this.showDialogSource = true;
    },
    getClass: () => {
      return "md-primary";
    },
    clear() {
      this.queryFilter = {
        id: null,
        name: null,
        is_public: null
      };
    }
  },
  closeDialogType() {
    this.queryFilter = {
      id: null,
      name: null,
      is_public: null
    };
    this.showDialogSource = false;
  }
};
</script>

<style lang="scss" scoped>
.md-dialog .md-dialog-container {
  width: 400px !important;
}
</style>
