<template>
  <div class="md-layout">
    <md-dialog :md-active.sync="showDialogSource">
      <md-dialog-content>
        <ValidationObserver ref="form">
          <form class="md-layout-item" @submit.prevent="onSubmit">
            <md-dialog-title>Cadastro de fonte</md-dialog-title>
            <md-dialog-actions>
              <md-button class="md-danger" @click="closeDialogSource"
                >Fechar</md-button
              >
              <md-button class="md-success" type="submit">Salvar</md-button>
            </md-dialog-actions>
            <md-card class="md-layout-item md-size-100">
              <md-card-content>
                <div class="md-layout md-gutter">
                  <div
                    class="md-layout md-layout-item md-size-50 md-xsmall-size-100"
                  >
                    <div class="md-layout-item md-size-100">
                      <ValidationProvider
                        name="nome"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <md-field>
                          <label for="name">Nome</label>
                          <md-input
                            name="name"
                            autocomplete="name"
                            v-model="dataSetSource.name"
                          />
                          <validation-error :errors="errors" />
                        </md-field>
                      </ValidationProvider>
                    </div>
                    <div class="md-layout-item md-size-100">
                      <ValidationProvider
                        name="nome fantasia"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <md-field>
                          <label for="name">Nome Fantasia</label>
                          <md-input
                            name="alias"
                            autocomplete="alias"
                            v-model="dataSetSource.alias"
                          />
                          <validation-error :errors="errors" />
                        </md-field>
                      </ValidationProvider>
                    </div>

                    <div class="md-layout-item md-size-100">
                      <md-field>
                        <label>Descrição</label>
                        <md-textarea
                          v-model="dataSetSource.detail"
                          md-counter="80"
                        ></md-textarea>
                      </md-field>
                    </div>
                  </div>
                  <div
                    class="md-layout md-layout-item md-size-50 md-xsmall-size-100 md-alignment-center-center"
                  >
                    <md-card class=" md-alignment-center-center">
                      <md-card-media
                        class="md-layout md-layout-item md-alignment-center-center"
                      >
                        <img
                          v-if="dataSetSource.logo == null"
                          :src="dataset"
                          alt="logo"
                          style="width: 120px;height: 120px;"
                        />
                        <img
                          v-else
                          :src="apiUrl + dataSetSource.logo"
                          alt="logo"
                          style="width: 120px;height: 120px;"
                        />
                      </md-card-media>
                      <md-card-actions v-if="dataSetSource.id">
                        <DialogCropper
                          :item.sync="dataSetSource"
                          :url.sync="url"
                          @addImage="getList()"
                        ></DialogCropper>
                      </md-card-actions>
                    </md-card>
                    <md-button class="md-primary" @click="clear()"
                      >Limpar</md-button
                    >
                  </div>
                </div>

                <div class="md-layout-item md-size-100">
                  <md-card>
                    <md-card-content>
                      <md-table
                        :value="table"
                        md-sort="name"
                        md-sort-order="asc"
                        md-card
                        class="paginated-table table-striped table-hover"
                        @md-selected="onSelect"
                      >
                        <md-table-toolbar>
                          <md-field>
                            <label>Per page</label>
                            <md-select
                              v-model="pagination.perPage"
                              name="pages"
                            >
                              <md-option
                                v-for="item in pagination.perPageOptions"
                                :key="item"
                                :label="item"
                                :value="item"
                              >
                                {{ item }}
                              </md-option>
                            </md-select>
                          </md-field>
                        </md-table-toolbar>

                        <md-table-row
                          slot="md-table-row"
                          slot-scope="{ item }"
                          :class="getClass(item)"
                          md-selectable="single"
                        >
                          <md-table-cell md-label="Name" md-sort-by="name">{{
                            item.name
                          }}</md-table-cell>
                          <md-table-cell md-label="Logo">
                            <md-avatar>
                              <img
                                v-if="item.logo == null"
                                :src="dataset"
                                :alt="item.name"
                              />
                              <img
                                v-else
                                :src="apiUrl + item.logo"
                                :alt="item.name"
                              />
                            </md-avatar>
                          </md-table-cell>
                          <md-table-cell md-label="Actions">
                            <md-button
                              class="md-accent"
                              @click="deleteItem(item.id)"
                            >
                              <md-icon>delete</md-icon>
                            </md-button>
                          </md-table-cell>
                        </md-table-row>
                      </md-table>
                    </md-card-content>

                    <md-card-actions md-alignment="space-between">
                      <div class="">
                        <p class="card-category">
                          Showing {{ from + 1 }} to {{ to }} of
                          {{ total }} entries
                        </p>
                      </div>
                      <pagination
                        class="pagination-no-border"
                        v-model="pagination.currentPage"
                        :per-page="pagination.perPage"
                        :total="total"
                        @input="setPage"
                      />
                    </md-card-actions>
                  </md-card>
                </div>
              </md-card-content>
            </md-card>
          </form>
        </ValidationObserver>
      </md-dialog-content>
    </md-dialog>

    <md-button class="md-primary md-raised" @click="showDialog()">
      Cadastar Fonte
    </md-button>
  </div>
</template>

<script>
import { ValidationError, Pagination } from "@/components";
import DialogCropper from "@/components/Form/DialogCropper";
import formMixin from "@/mixins/form-mixin";
import Vue from "vue";

export default {
  components: {
    ValidationError,
    Pagination,
    DialogCropper
  },
  mixins: [formMixin],
  props: {
    dataset: {
      type: String,
      default: require("@/assets/img/dataset.png")
    }
  },
  data() {
    return {
      showDialogSource: false,
      logo: {},
      initialImage: null,
      dataSetSource: { id: null, name: null, detail: null, logo: null },
      url: "v1/source-dataset/",
      table: [],
      total: 0,
      footerTable: ["Nome", "Ações"],

      query: null,

      sortation: {
        field: "name",
        order: "asc"
      },

      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [10, 15, 20, 25, 50]
      },

      apiUrl: `${process.env.VUE_APP_API_BASE_URL}/`
    };
  },
  created() {},
  computed: {
    sort() {
      if (this.sortation.order === "desc") {
        return `-${this.sortation.field}`;
      }

      return this.sortation.field;
    },

    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    }
  },
  methods: {
    setPage(page) {
      this.pagination.currentPage = page;
      this.getList();
    },
    async getList() {
      try {
        let result = await Vue.$http.get(
          `${this.url}?per_page=${this.pagination.perPage}&page=${this.pagination.currentPage}`
        );
        this.table = result.data.data;
        this.table.forEach(async item => {
          if (item.logo != null) {
            item.logo = await item.logo.replace("public", "storage");
          }
        });
        this.total = result.data.total;
        this.dataSetSource = { id: null, name: null, detail: null, logo: null };
      } catch (e) {
        //await this.$store.dispatch("alerts/error", e.response.data.message);
        //this.setApiValidation(e.response.data.errors);
      }
    },
    async alert(message) {
      await this.$store.dispatch("alerts/error", message);
    },
    async create() {
      try {
        await Vue.$http.post(`${this.url}`, this.dataSetSource);
        this.getList();
        this.logo.remove();
        this.dataSetSource = { name: null, detail: null, logo: null };
        this.show = false;
        await this.$store.dispatch("alerts/success", "Recurso Atualizado!");
        this.$emit("addSource", { addSource: true });
      } catch (error) {
        const message =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
        console.log(message);
        for (let e in error.response.data.errors) {
          await this.$store.dispatch(
            "alerts/error",
            error.response.data.errors[e]
          );
        }
        this.show = false;
      }
    },
    async update() {
      try {
        await Vue.$http.put(
          `${this.url}${this.dataSetSource.id}`,
          this.dataSetSource
        );
        this.getList();
        this.logo.remove();
        this.dataSetSource = { name: null, detail: null, logo: null };
        this.show = false;
        await this.$store.dispatch("alerts/success", "Recurso Atualizado!");
        this.$emit("addSource", { addSource: true });
      } catch (error) {
        const message =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
        console.log(message);
        for (let e in error.response.data.errors) {
          await this.$store.dispatch(
            "alerts/error",
            error.response.data.errors[e]
          );
        }
        this.show = false;
      }
    },
    async onSubmit() {
      this.$refs.form.validate().then(async success => {
        if (!success) {
          let values = Object.values(this.$refs.form.errors);
          values.forEach(value => {
            value.forEach(message => {
              this.alert(message);
            });
          });
          return;
        }

        if (this.dataSetSource.id == null) {
          await this.create();
        } else {
          await this.update();
        }
      });
    },
    customSort() {
      return false;
    },
    async deleteItem(id) {
      await Vue.$http.delete(`${this.url}${id}`);
      this.getList();
    },
    showDialog() {
      this.showDialogSource = true;
      this.getList();
    },
    closeDialogSource() {
      this.dataSetSource = { id: null, name: null, detail: null, logo: null };
      this.showDialogSource = false;
    },
    getClass: () => {
      return "md-primary";
    },
    async onSelect(item) {
      this.dataSetSource = {
        id: item.id,
        name: item.name,
        alias: item.alias,
        detail: item.detail,
        logo: item.logo
      };
    },
    clear() {
      this.dataSetSource = { id: null, name: null, detail: null, logo: null };
    }
  }
};
</script>

<style lang="scss" scoped>
.md-dialog .md-dialog-container {
  width: 60% !important;
}
</style>
