<template>
  <div class="md-layout-item md-size-100 md-layout">
    <div class="md-layout-item md-size-100" v-show="load" style="padding: 0">
      <md-progress-bar
        class="md-accent"
        md-mode="indeterminate"
      ></md-progress-bar>
    </div>
    <div class="md-layout-item md-size-100" v-show="!load" style="padding: 0">
      <ValidationObserver
        ref="form"
        class="md-layout md-alignment-center-center"
      >
        <form
          @submit.prevent="onSubmit"
          class="md-layout-item md-size-100"
          style="padding: 0"
        >
          <md-card>
            <md-card-header class="md-card-header-icon md-card-header-danger">
              <div class="card-icon">
                <md-icon>backup</md-icon>
              </div>
              <h4 class="title">Upload de arquivo {{ title }}</h4>
            </md-card-header>

            <md-card-content>
              <div class="md-layout">
                <div class="md-layout-item">
                  <md-field class="md-invalid">
                    <md-file
                      v-model="files"
                      @md-change="onFileUpload($event)"
                      multiple
                    />
                    <span
                      class="md-error"
                      v-for="message in messageValidate"
                      :key="message"
                      >{{ message }}</span
                    >
                  </md-field>
                </div>
              </div>
              <div class="md-layout" v-if="isDate">
                <div class="md-layout-item">
                  <ValidationProvider
                    name="Data"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <md-field class="md-invalid">
                      <md-datepicker v-model="date" />
                      <validation-error :errors="errors" />
                    </md-field>
                  </ValidationProvider>
                </div>
              </div>
            </md-card-content>

            <md-card-actions>
              <md-button
                v-if="isApi"
                to="/mosaico/datasets/calendar/brasilio/covid"
                class="md-indigo"
              >
                Update por API
              </md-button>
              <md-button
                type="submit"
                class="md-danger"
                v-if="statusButtonSubmit"
              >
                Upload
              </md-button>
              <md-button
                type="submit"
                class="md-danger"
                disabled
                v-if="!statusButtonSubmit"
              >
                Upload
              </md-button>
            </md-card-actions>
          </md-card>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>
import { ValidationError } from "@/components";
import formMixin from "@/mixins/form-mixin";
import moment from "moment-mini";
import Vue from "vue";

export default {
  props: {
    title: null,
    type: String,
    source: String
  },
  computed: {
    isApi() {
      let result = false;
      this.sourcesByApi.forEach(source => {
        if (source == this.source) {
          console.log(source, this.source);
          result = true;
        }
      });
      return result;
    },
    isDate() {
      let result = false;
      this.datasetsByDate.forEach(type => {
        if (type == this.type) {
          console.log(type, this.type);
          result = true;
        }
      });
      return result;
    }
  },
  components: {
    ValidationError
  },
  mixins: [formMixin],
  data() {
    return {
      sourcesByApi: ["brasilio"],
      datasetsByDate: ["occupation_of_health_units"],
      load: false,
      files: null,
      datasets: null,
      url: `v1/dataset/${this.source}/${this.type}`,
      date: null,
      messageValidate: [],
      statusButtonSubmit: false
    };
  },
  methods: {
    async onFileUpload(event) {
      this.datasets = [];
      if (this.validateFiles(event)) {
        this.datasets = event;
      }
    },
    async validateFiles(files) {
      let size = 0;
      this.messageValidate = [];
      for (let index = 0; index < files.length; index++) {
        size += files[index].size;
      }
      if (files.length < 1) {
        this.messageValidate.push("Deve selecionnar pelo menos um arquivo");
        return false;
      }
      let limit = 503316480;
      if (this.source == "cnes") {
        limit = 5033164800;
      }
      if (size > limit) {
        this.messageValidate.push("O tamanho máximo 64M");
        return false;
      }
      this.statusButtonSubmit = true;
      return true;
    },
    async sendFile() {
      try {
        let response;
        if (this.date != null) {
          let formatDate = moment(this.date).format("YYYY-MM-DD");
          let formData = new FormData();
          for (let index = 0; index < this.datasets.length; index++) {
            formData.append("datasets[]", this.datasets[index]);
          }
          let response = await Vue.$http.post(
            `${this.url}?date=${formatDate}`,
            formData,
            {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": `multipart/form-data; boundary=${formData._boundary}`
              },
              timeout: 9999999
            }
          );
          this.$emit("uploadFile");
          this.load = false;
          return await response.data;
        } else {
          let formData = new FormData();
          for (let index = 0; index < this.datasets.length; index++) {
            formData.append("datasets[]", this.datasets[index]);
          }
          response = await Vue.$http.post(`${this.url}`, formData, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": `multipart/form-data; boundary=${formData._boundary}`
            },
            timeout: 9999999
          });
          this.$emit("uploadFile");
          this.load = false;
          await this.$store.dispatch("alerts/success", "Arquivo Carregado!");
          return await response.data;
        }
      } catch (error) {
        this.load = false;
        console.log(error.response);
        await this.$store.dispatch("alerts/error", error);
        return await error.response;
      }
    },
    async onSubmit() {
      if (this.datasets != null) {
        this.load = true;
        this.sendFile();
      }
    }
  }
};
</script>

<style></style>
